var site = site || {};
site.locator = site.locator || {};
var settings = Drupal.settings.global_variables || {};
var donot_filter_store_results = settings.donot_filter_store_result_types || 0;

site.locator.geocodeResultFilter = function (geocodeResults) {
  var returnedResults = jQuery.extend([], geocodeResults),
      unacceptableMatches = [
          "country",
          "route"
        ],
      unacceptablePartialMatches = [
          "point_of_interest",
          "premise",
          "subpremise"
        ],
      i,
      j,
      breakOuterLoop;

  if (donot_filter_store_results) {
    unacceptableMatches = [];
    unacceptablePartialMatches = [];
  }
  for (i = 0; i < returnedResults.length; i += 1, breakOuterLoop = false) {
    for (j = 0; j < unacceptableMatches.length; j += 1) {
      if (jQuery.inArray(unacceptableMatches[j], returnedResults[i].types) !== -1) {
        returnedResults.splice(i);
        breakOuterLoop = true;
        break;
      }
    }
    if (breakOuterLoop) {
      break;
    }
    if (returnedResults[i].partial_match) {
      for (j = 0; j < unacceptablePartialMatches.length; j += 1) {
        if (jQuery.inArray(unacceptablePartialMatches[j], returnedResults[i].types) !== -1) {
          returnedResults.splice(i);
          break;
        }
      }
    }
  }

  return returnedResults;
}

// factory function, for CL map pins for store locator
site.locator.MapLocationIcon = (function () {
  var MapLocationIcon = function (args) {
      this.id = args.id;
      this.label = args.label; // the content to be fetured inside the custom map pin
      this.icon_class = args.icon_class; //the position of the spritesheet for the icon background
      this.pos = args.latlng;
      this.set("position", args.latlng);
      this.setMap(args.map);
      this.clickHandler = args.clickHandler;
    },
    initialize = function () {
      MapLocationIcon.prototype = new google.maps.OverlayView();
      MapLocationIcon.prototype.onRemove = function () {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      };

      //prepare the overlay with DOM
      MapLocationIcon.prototype.onAdd = function () {
        div = document.createElement('div');
        $(div).addClass('map-marker').addClass(this.icon_class);
        $(div).css({
          'position': 'absolute'
        });
        $(div).text(this.label);
        $(div).click(this.clickHandler);

        this.div = div;

        var panes = this.getPanes();
        panes.overlayImage.appendChild(div);
      }

      //set position
      MapLocationIcon.prototype.draw = function() {
        var overlayProjection = this.getProjection();
        var position = overlayProjection.fromLatLngToDivPixel(this.pos);
        var panes = this.getPanes();
        $(this.div).css({
          'left': position.x - 14.5 + 'px',
          'top':  position.y - 44 + 'px'
        });
      }
    },
    initialized = false;

    return function (args) {
      if (!initialized) {
        if (google.maps) {
          initialize();
          initialized = true;
        } else {
          throw new Error("Can't instantiate MapLocationIcon: google.maps is not yet available.");
        }
      }

      return new MapLocationIcon(args);
    };
}());
